import { FC } from 'react';
import { LogoutButtonDefault } from './default/logoutButton.default';
import { LogoutProps, LogoutSkinProps } from './logoutButton.props';
import { useLogout } from '@/api';

export const hasLogoutProps = (
  logoutProps: boolean | LogoutProps,
): logoutProps is LogoutProps =>
  (logoutProps as LogoutProps).lastItem !== undefined ||
  (logoutProps as LogoutProps).execute !== undefined ||
  (logoutProps as LogoutProps).isLoading !== undefined ||
  (logoutProps as LogoutProps).disabled !== undefined;

export const LogoutButton: FC<LogoutSkinProps> = ({
  children,
  showLogoutButton,
  altBackgroundForDesktop,
}) => {
  const { isLoading, logout } = useLogout();
  if (showLogoutButton) {
    const showLogout = hasLogoutProps(showLogoutButton) ? showLogoutButton : {};
    showLogoutButton = showLogout;
    showLogoutButton.isLoading = showLogout?.isLoading ?? isLoading;
  }

  const prop: LogoutProps = {
    ...showLogoutButton,
    execute: () => {
      showLogoutButton.execute?.();
      if (!showLogoutButton.preventLogout) {
        logout('/authorize');
      }
    },
    altBackgroundForDesktop,
  };

  return <LogoutButtonDefault {...prop}>{children}</LogoutButtonDefault>;
};
