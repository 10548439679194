import {
  ClientTemplate,
  IStorage,
  NosIdAppContextType,
  bootstrapApp,
  createNosIdApplicationContext,
  setUpI18n,
  useHasToApplyDarkTheme,
  useTheme,
} from '@nosinovacao/nosid-mfe-common';
import './index.css';
import '@nosinovacao/nosid-mfe-common/dist/style.css';
import ReactDOM from 'react-dom/client';
import { CLIENT_ID_STORAGE_KEY } from '@/constants';
import { BlackListDomain, SignInConfiguration } from '@/models';
import { AppContextType, createApplicationContext } from '@/context';
import { App } from '@/App';

function getDomainBlackListed(
  env: SignInConfiguration,
): BlackListDomain | undefined {
  const blackList = env.PortalRedirectsBlackList;
  const params = new URLSearchParams(window.location.search);

  const clientIdParam =
    params.get('client_id') ?? sessionStorage.getItem(CLIENT_ID_STORAGE_KEY);

  const domainBlackListed = blackList?.find(({ domain, clientId }) => {
    const hasDomain = window.location.host === domain;
    const hasClientId = clientId === clientIdParam;
    return (hasClientId && hasDomain) || hasDomain;
  });

  if (!domainBlackListed) {
    return domainBlackListed;
  }

  const specialRouteByPath = domainBlackListed.specialRoutes?.find((t) =>
    t.routeNames?.some((tt) => tt === window.location.pathname),
  );

  const specialRouteByClientId = domainBlackListed.specialRoutes?.find(
    (t) => t.clientId === clientIdParam,
  );

  return specialRouteByPath ?? specialRouteByClientId ?? domainBlackListed;
}

function validateBlackList(
  context: AppContextType,
  nosidContext: NosIdAppContextType,
  domainBlackListed: BlackListDomain,
  loginHint: string,
): boolean {
  const data = context.navigationStateService.getCurrentState();

  if (!domainBlackListed) {
    return true;
  }

  const loginHintCombined = loginHint || (data?.LoginHint as string);

  if (window.location.pathname === '/' && !loginHintCombined) {
    return false;
  }
  const specialRoute = domainBlackListed.specialRoutes?.find((t) =>
    t.routeNames?.some((tt) => tt === window.location.pathname),
  );

  if (specialRoute) {
    return validateBlackList(
      context,
      nosidContext,
      specialRoute,
      loginHintCombined,
    );
  }

  return !domainBlackListed.loginHintRequired || !!loginHintCombined;
}

void bootstrapApp<SignInConfiguration>(async ({ env, templates, widgets }) => {
  const params = new URLSearchParams(window.location.search);
  const loginHint = params.get('login_hint')?.replace(/ /g, '+');

  const domainBlackListed = getDomainBlackListed(env);

  const nosidContext = createNosIdApplicationContext(
    env,
    templates,
    widgets,
    CLIENT_ID_STORAGE_KEY,
    'SignIn',
    env.APIConfiguration.Mage,
    domainBlackListed?.clientId,
  );

  const context = createApplicationContext(
    env,
    nosidContext.localStorageService,
    nosidContext.sessionStorageService,
    nosidContext,
  );

  if (loginHint) {
    context.loginHintContextService.loginHintContext = loginHint;
  }

  if (!loginHintOptional(env, context, nosidContext, loginHint)) {
    return;
  }

  if (
    redirectToPortal(
      env,
      context,
      nosidContext.sessionStorageService,
      nosidContext.templates.clientTemplate,
      nosidContext,
      loginHint,
    )
  ) {
    return;
  }

  const canUseDarkTheme = useHasToApplyDarkTheme(
    nosidContext.templates.clientTemplate,
  );

  useTheme({
    variant: nosidContext.templates.clientTemplate.App,
    isdarkmode: canUseDarkTheme,
    templates: nosidContext.templates,
  });

  if (window.location.pathname === '/') {
    redirectToLinkWithParameters(nosidContext, env, context);
    return;
  }

  if (!nosidContext.templates.clientTemplate.hideCookiebot) {
    nosidContext.cookieBotService.addCookiebot();
  }

  const translationOptions: (typeof nosidContext)['config']['Translations'] = {
    ...nosidContext.config.Translations,
    Options:
      nosidContext.templates.clientTemplate.AvailableLanguages ??
      nosidContext.config.Translations.Options,
  };

  await setUpI18n(translationOptions, navigator.language);

  ReactDOM.createRoot(document.getElementById('root') as HTMLElement).render(
    <App context={context} nosidContext={nosidContext} />,
  );
});

function redirectToLinkWithParameters(
  nosidContext: NosIdAppContextType,
  env: SignInConfiguration,
  context: AppContextType,
) {
  const redirectUrl =
    nosidContext.templates.clientTemplate.PortalLink ?? env.NosIdPortalLink;
  const urlPameters = new URLSearchParams(window.location.search);
  urlPameters.append(
    'client_id',
    nosidContext.templates.clientTemplate.ClientId,
  );
  context.utilsService.redirect(`${redirectUrl}?${urlPameters.toString()}`);
}

// ! This logic should be moved to nginx or haproxy to prevent redirects being made in the browser.
// ! A 302 redirect is the best way to do this instead of bootstrapping the app and then redirecting.
function redirectToPortal(
  env: SignInConfiguration,
  context: AppContextType,
  sessionStorageService: IStorage,
  template: ClientTemplate,
  nosidAppContext: NosIdAppContextType,
  loginHint: string | undefined,
): boolean {
  const portalRedirectMap = env.PortalRedirects;
  if (!portalRedirectMap) {
    return false;
  }
  // eslint-disable-next-line no-restricted-syntax
  for (const key of Object.keys(portalRedirectMap)) {
    const { isValid, routeParams } = isValidRouteWithCurrentLocation(key);
    if (!isValid) {
      // eslint-disable-next-line no-continue
      continue;
    }
    if (
      !ensurePortalRedirectOrNotFound(
        env,
        context,
        sessionStorageService,
        nosidAppContext,
        loginHint ?? '',
      )
    ) {
      return true;
    }
    const newPath = Object.keys(routeParams ?? {}).reduce(
      (acc, curr) => acc.replace(curr, routeParams?.[curr]),
      portalRedirectMap[key],
    );

    const originalUrl = `${window.location.origin}${window.location.pathname}`;
    const newUrl = `${template.PortalLink ?? env.NosIdPortalLink}${newPath}`;
    const newUri = window.location.href.replace(originalUrl, newUrl);
    context.utilsService.redirect(newUri);
    return true;
  }
  return false;
}

function isValidRouteWithCurrentLocation(keyPath: string): {
  isValid: boolean;
  routeParams?: Record<string, any>;
} {
  if (keyPath.toLowerCase() === window.location.pathname.toLowerCase()) {
    return { isValid: true, routeParams: {} };
  }
  const splittedPath = keyPath.split('/');
  const splittedCurrentPath = window.location.pathname.split('/');
  if (splittedPath.length !== splittedCurrentPath.length) {
    return { isValid: false };
  }
  const routeParams: any = {};
  // eslint-disable-next-line no-plusplus
  for (let i = 0; i < splittedPath.length; i++) {
    const subpath = splittedPath[i];
    const currentLocationSubpath = splittedCurrentPath[i];
    const isPathParam =
      subpath.indexOf('{') === 0 && subpath.indexOf('}') === subpath.length - 1;

    if (isPathParam) {
      routeParams[subpath] = currentLocationSubpath;
      // eslint-disable-next-line no-continue
      continue;
    }
    if (subpath.toLowerCase() !== currentLocationSubpath.toLowerCase()) {
      return { isValid: false };
    }
  }

  return { isValid: true, routeParams };
}

function loginHintOptional(
  env: SignInConfiguration,
  context: AppContextType,
  nosidContext: NosIdAppContextType,
  loginHint: string | undefined,
): boolean {
  const domainBlackListed = getDomainBlackListed(env);

  if (!domainBlackListed) {
    return true;
  }

  if (
    validateBlackList(context, nosidContext, domainBlackListed, loginHint ?? '')
  ) {
    return true;
  }

  if (window.location.pathname === '/generic/forbidden') {
    return true;
  }

  nosidContext.sessionStorageService.setString(
    CLIENT_ID_STORAGE_KEY,
    domainBlackListed.clientId,
  );

  context.utilsService.redirect(`${window.location.origin}/generic/forbidden`);
  return false;
}

function ensurePortalRedirectOrNotFound(
  env: SignInConfiguration,
  context: AppContextType,
  sessionStorageService: IStorage,
  nosidAppContext: NosIdAppContextType,
  loginHint: string,
): boolean {
  const domainBlackListed = getDomainBlackListed(env);

  if (!domainBlackListed) {
    return true;
  }

  if (
    validateBlackList(context, nosidAppContext, domainBlackListed, loginHint)
  ) {
    return true;
  }

  sessionStorageService.setString(
    CLIENT_ID_STORAGE_KEY,
    domainBlackListed.clientId,
  );
  // we don't want go to portal, instead we redirect to the app not available page
  context.utilsService.redirect(`${window.location.origin}/generic/forbidden`);
  return false;
}
