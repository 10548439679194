import { htmlClass } from '@nosinovacao/nosid-mfe-common';
import { FC } from 'react';
import { ContainerSkinProps } from '../container.props';

export const ContainerCyber: FC<ContainerSkinProps> = ({
  children,
  className,
  el,
  onSubmit,
}) => {
  const klass = htmlClass(
    'overflow-x-hidden relative pt-6  flex flex-col flex-nowrap justify-start items-start  z-10 max-desktop-sm:h-svh',
    'tablet-sm:pt-[120px] tablet-sm:mx-auto ',
    'tablet-st:px-0',
    'desktop-sm:w-svw desktop-sm:shadow-level4 desktop-sm:rounded-lg desktop-sm:min-h-containerMinHeight desktop-sm:overflow-y-auto desktop-sm:max-h-containerMaxHeight desktop-sm:items-center  desktop-sm:mx-0 desktop-sm:max-w-[636px] desktop-sm:pt-0 desktop-sm:overflow-hidden',
    className ?? '',
    'bg-cyberGrey1 desktop-sm:bg-cyberDarkPurple desktop-sm:p-[1px]',
  );

  const containerClass = htmlClass(
    ' desktop-sm:flex desktop-sm:justify-center desktop-sm:items-center desktop-sm:h-[100dvh] desktop-sm:bg-cyberBackgroundGradient bg-no-repeat bg-fixed',
  );

  switch (el) {
    case 'form':
      return (
        <div className={containerClass}>
          <form
            className={klass}
            onSubmit={(ev) => {
              onSubmit?.(ev as unknown as Event);
            }}>
            {children}
          </form>
        </div>
      );

    case 'div':
    default:
      return (
        <div className={containerClass}>
          <div className={klass}>{children}</div>
        </div>
      );
  }
};
