import { FC } from 'react';
import { useAppContext } from '@/context';
import { IconLogoWoo } from './woo/iconLogo.woo';
import { IconLogoDefault } from './default/iconLogo.default';
import { IconLogoBenfica } from './benfica/iconLogo.benfica';
import { IconLogoSporting } from './sporting/iconLogo.sporting';
import { LogoProps } from './iconLogo.props';
import { IconLogoCyber } from './cyber/iconLogo.cyber';

export const IconLogo: FC<LogoProps> = ({ ...props }) => {
  const { templates } = useAppContext();
  switch (true) {
    case templates.isWtfTemplate:
      return <></>;
    case templates.isWooTemplate:
      return <IconLogoWoo {...props} />;
    case templates.isBenficaTemplate:
      return <IconLogoBenfica {...props} />;
    case templates.isSportingTemplate:
      return <IconLogoSporting {...props} />;
    case templates.isCyberInspect:
      return <IconLogoCyber {...props} />;
    default:
      return <IconLogoDefault {...props} />;
  }
};
